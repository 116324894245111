
// 平台表格
export const fileTable = (content) => {
  return [

    {
      label: '文件类型',
      prop: 'docName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '文件名称',
      prop: 'fileName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '操作',
      prop: 'action',
      width: '160px'
    }
  ]
}

export const contractFileInfo = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '文件名称',
      prop: 'docName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '操作',
      prop: 'action',
      width: '160px'
    }
  ]
}
