
// 表格合并规则
export function filterData (arr = [], innerKey, orderKey) {
  const res = []
  const map = {}
  arr.forEach((item) => {
    const key = item[innerKey]
    item[orderKey] = 0
    if (!map[key]) {
      map[key] = [item]
    } else {
      map[key].push(item)
    }
  })
  for (const key in map) {
    const list = map[key]
    list[0][orderKey] = list.length
    res.push(...list)
  }
  return res
}
